export const navy = "#001F3F";
export const blueLighter = "#f0fbff";
export const blue = "#0074D9";
export const blueDarker = "#005daa";
export const aqua = "#7FDBFF";
export const teal = "#39CCCC";
export const olive = "#3D9970";
export const green = "#2ECC40";
export const lime = "#01FF70";
export const yellow = "#FFDC00";
export const orange = "#FF851B";
export const red = "#FF4136";
export const fuchsia = "#F012BE";
export const purple = "#B10DC9";
export const maroon = "#85144B";
export const white = "#FFFFFF";
export const gray = "#AAAAAA";
export const grayDark = "#5e5e5e";
export const grayDarker = "#3a3a3a";
export const silver = "#DDDDDD";
export const black = "#111111";
